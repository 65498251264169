import React, { FC, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import {
    LiveBookingEditRequest,
    LiveBookingGetResponse,
    SuccessResponse,
} from "@/lib/aspida/api/@types"
import { Head } from "@/components/Head"
import { GlobalLayout } from "@/components/GlobalLayout"
import {
    CustomInput,
    CustomSelect,
    getFormattedLiveTitle,
} from "@/components/pages/LiveBooking/LiveBooking.component"
import { Link, navigate } from "gatsby"
import { useApiRequest } from "@/hooks/api/useApiRequest"
import { getTokenFromQueryParam } from "@/components/pages/LiveBooking/url"
import { client } from "@/lib/aspida/client"
import { CircularProgress } from "@mui/material"
import { FormInput } from "@/components/form/FormInput"
import { createLiveBookingCancelUrl } from "@/components/pages/LiveBooking/Cancel/url"
import BtnKakuLoadAnime from "@/components/btn/BtnKakuLoadAnime"

export const LiveBookingEdit: FC = () => {
    const token = getTokenFromQueryParam()
    const liveBookingFetchApi = useApiRequest<LiveBookingGetResponse>()
    const liveBookingUpdateApi = useApiRequest<SuccessResponse>()
    const [isDisabled, setIsDisabled] = useState<boolean>()

    const {
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        control,
    } = useForm<LiveBookingEditRequest>({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            ticketCount: 1,
        },
    })

    useEffect(() => {
        if (!token) {
            navigate("/")
            return
        }

        liveBookingFetchApi.request(
            async () => {
                const result = await client.v1.live_booking._token(token).get()
                setIsDisabled(false)
                setValue("token", token)
                setValue("ticketCount", result.body.liveBooking.ticketCount)
                setValue("name", result.body.liveBooking.name)
                setValue("otherRequest", result.body.liveBooking.otherRequest)
                return result
            },
            {
                onFailure: () => {
                    setIsDisabled(true)
                },
            }
        )
    }, [])

    /**
     * 送信ボタンを押した際のハンドリング
     */
    const onSubmitButtonClick = useCallback(() => {
        if (!isValid) {
            alert("必須項目を満たしてから内容確認を行ってください")
        }
    }, [isValid])

    /**
     * 送信時のハンドリング
     */
    const onSubmit: SubmitHandler<LiveBookingEditRequest> = useCallback(
        async (data) => {
            if (!confirm("内容を送信します。\nよろしいですか？")) {
                return
            }

            await liveBookingUpdateApi.request(
                async () => {
                    return client.v1.live_booking.edit.put({
                        body: data,
                    })
                },
                {
                    onSuccess: () => {
                        // リダイレクト
                        navigate("/live-booking/edit/send", {
                            state: {
                                toAddress:
                                    liveBookingFetchApi.apiRequestState
                                        .response!.liveBooking.toAddress,
                            },
                        })
                    },
                    onFailure: (e) => {
                        if (e.status === 500 || !e.message) {
                            alert(
                                "ただいまメールをお送りすることができません。\n申し訳ございませんが、しばらく時間がたった後再度送信の程よろしくお願いいたします。"
                            )
                            return
                        }
                        alert(e.message)
                    },
                }
            )
        },
        [liveBookingFetchApi, liveBookingUpdateApi]
    )

    return (
        <GlobalLayout Head={<Head title="チケット予約編集フォーム" noIndex />}>
            <section className="p-section u-mb-100px">
                <h2 className="p-section__title u-mb-50px">
                    チケット予約編集フォーム
                    <p className="p-section__sub-title">
                        必須項目を記載の上、送信をお願いします
                    </p>
                </h2>
                {liveBookingFetchApi.apiRequestState.isLoading ? (
                    <CircularProgress />
                ) : liveBookingFetchApi.apiRequestState.error ? (
                    <p>
                        予約情報を取得できませんでした。キャンセル処理済みの可能性があります。
                    </p>
                ) : liveBookingFetchApi.apiRequestState.response ? (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="p-form u-mr-3 u-ml-3 p-section__content"
                    >
                        <div className={"mb-11 text-sm text-gray-500"}>
                            ※予約キャンセルを行いたい場合は
                            <Link
                                to={createLiveBookingCancelUrl({
                                    token: token!,
                                })}
                                className={"text-sky-600"}
                            >
                                こちら
                            </Link>
                        </div>
                        <FormInput
                            value={getFormattedLiveTitle({
                                ...liveBookingFetchApi.apiRequestState.response
                                    .live,
                            })}
                            className={`u-width-100 u-mb-10 rsm:text-xs`}
                            disabled={true}
                            label={"ライブ情報"}
                            name={"slug"}
                        />
                        <FormInput
                            value={
                                liveBookingFetchApi.apiRequestState.response
                                    .liveBooking.toAddress
                            }
                            className={`u-width-100 u-mb-10 rsm:text-xs`}
                            disabled={true}
                            label={"メールアドレス"}
                            name={"toAddress"}
                        />
                        <CustomSelect
                            name={"ticketCount"}
                            className={"u-width-40 u-mb-10 rsm:text-xs"}
                            control={control as any}
                            errors={errors}
                            disabled={isDisabled}
                            options={Array.from({
                                length: 10,
                            }).map((_, i) => ({
                                label: String(i + 1),
                                value: i + 1,
                            }))}
                            defaultValue={1}
                        />

                        <CustomInput
                            className="u-mb-10 rsm:text-xs"
                            name={"name"}
                            control={control as any}
                            errors={errors}
                            disabled={isDisabled}
                        />
                        <CustomInput
                            className="u-mb-10 rsm:text-xs"
                            name={"otherRequest"}
                            control={control as any}
                            errors={errors}
                            disabled={isDisabled}
                        />

                        <div className="p-form__submit">
                            <BtnKakuLoadAnime
                                link="#"
                                type="submit"
                                isActive={
                                    liveBookingUpdateApi.apiRequestState
                                        .isLoading
                                }
                                onClick={onSubmitButtonClick}
                                disabled={isDisabled}
                            >
                                送信する
                            </BtnKakuLoadAnime>
                        </div>
                    </form>
                ) : (
                    <></>
                )}
            </section>
        </GlobalLayout>
    )
}
